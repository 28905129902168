"use client";

import {
  forwardRef,
  ReactNode,
  ElementRef,
  ComponentPropsWithoutRef,
} from "react";
import { Trigger, Provider, Root, Content } from "@radix-ui/react-tooltip";
import { FiArrowUpRight } from "react-icons/fi";

import { cn } from "@/utils";

const ToolTip = ({
  content,
  children = (
    <FiArrowUpRight className="text-3xl text-primary group-hover:text-accent" />
  ),
}: {
  content: string;
  children?: ReactNode;
}) => {
  const lines = content.split("\\");
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <Trigger
          title={content}
          asChild>
          {children}
        </Trigger>
        <TooltipContent>
          {lines.map((line) => (
            <p key={"line-" + line}>{line}</p>
          ))}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const TooltipProvider = Provider;

const Tooltip = Root;

const TooltipTrigger = Trigger;

const TooltipContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content>
>(({ className, sideOffset = 8, ...props }, ref) => (
  <Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-full border bg-dark px-3 py-1.5 text-center text-sm text-white animate-in fade-in-0 zoom-in-95",
      "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = Content.displayName;

export default ToolTip;
export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
