"use client";
import dynamic from "next/dynamic";
import { useRef, ReactNode } from "react";

const HeroPortfolio = dynamic(
  () => import("@/components/Hero/Portfolio/HeroPortfolio"),
  { ssr: false }
);

const HeroWrapper = ({ children }: { children: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <section
      id="hero"
      ref={ref}
      className="text-clip ~pb-48/56">
      {children}
      <div className={"overflow-x-clip"}>
        <HeroPortfolio htmlTarget={ref} />
      </div>
    </section>
  );
};

export default HeroWrapper;
