"use client";
import { useRef, useState, useEffect } from "react";
import { CountUp } from "use-count-up";
import { useInView } from "@/hooks/useFramerMotion";

export const YearsCounter = () => {
  const refYears = useRef<HTMLDivElement>(null);

  const isInViewOnce = useInView(refYears, { once: true });

  const years = new Date().getFullYear() - 2001;

  const [shouldRunCounter, setShouldRunCounter] = useState(false);
  useEffect(() => {
    if (isInViewOnce && !shouldRunCounter) {
      setTimeout(() => setShouldRunCounter(true), 1000);
    }
  }, [isInViewOnce, shouldRunCounter]);

  return (
    <>
      <span
        ref={refYears}
        className="inline-block text-center text-accent ~min-w-[1.8rem]/[2.2rem]">
        +
        {
          <CountUp
            duration={5}
            isCounting={shouldRunCounter}
            start={1}
            end={years}
            easing="easeOutCubic"
            formatter={(value) => value.toFixed()}
          />
        }
      </span>
    </>
  );
};
