"use client";
import useFramerMotion, {
  LazyMotion,
  m,
  useScroll,
  useSpring,
  useTransform,
  type MotionValuePatch as MotionValue,
} from "@/hooks/useFramerMotion";

const springConfig = { stiffness: 150, damping: 50 /*, bounce: 50*/ };

const HeroIndicator = ({ children }: { children: React.ReactNode }) => {
  const framerMotionFeatures = useFramerMotion();
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 50], [1, 0]) as MotionValue<number>;
  const translateY = useSpring(
    useTransform(scrollY, [0, 50], [0, -50]),
    springConfig
  ) as MotionValue<number>;

  return (
    <LazyMotion features={framerMotionFeatures}>
      <div className="absolute left-1/2 top-[100svh] z-40 -translate-x-1/2 -translate-y-full">
        <m.div style={{ translateY, opacity, willChange: "transform" }}>
          {children}
        </m.div>
      </div>
    </LazyMotion>
  );
};

export default HeroIndicator;
