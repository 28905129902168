"use client";
import { useSceneContext } from "@/contexts/SceneContext";

const ChangeHologram = ({
  hologram = 0,
  children,
}: {
  hologram: number;
  children: React.ReactNode;
}) => {
  const { changeHologram, revertHologram } = useSceneContext();

  return (
    <>
      <div
        onMouseEnter={() => {
          changeHologram(hologram);
        }}
        onMouseLeave={() => {
          revertHologram();
        }}>
        {children}
      </div>
    </>
  );
};

ChangeHologram.displayName = "ChangeHologram";

export default ChangeHologram;
