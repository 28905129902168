"use client";
import { useDetectGPU } from "@react-three/drei";
import {
  useState,
  createContext,
  useContext,
  ReactNode,
  Suspense,
  useRef,
  MutableRefObject,
  useCallback,
  useEffect,
} from "react";
import { useIsClient } from "usehooks-ts";

type Vector3 = { x: number; y: number; z: number };

export interface SceneContextProps {
  quality: "low" | "medium" | "high";
  is404: boolean;
  setIs404: (_state: boolean) => void;
  routeHologram: MutableRefObject<number>;
  currentHologram: number;
  setCurrentHologram: (_state: number) => void;
  changeHologram: (_state: number) => void;
  revertHologram: () => void;
  clientBannerScene: { sunPosition: Vector3 | undefined; sunAngle: number };
  isFooterContactHovered: MutableRefObject<boolean>;
}

const context = createContext<SceneContextProps>(null!);

export const SceneContextProvider = ({ children }: { children: ReactNode }) => {
  const routeHologram = useRef(-1);
  const isClient = useIsClient();
  const isFooterContactHovered = useRef(false);
  const [currentHologram, setCurrentHologram] = useState(-1);
  const [is404, setIs404] = useState(false);
  const GPU = useDetectGPU({ benchmarksURL: "/benchmarks-min" });
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  let quality: SceneContextProps["quality"] = "high";
  if (GPU.isMobile) {
    quality = "medium";
  }
  if (GPU.tier < 2) {
    quality = "low";
  }
  if (isClient) {
    const { userAgent, platform, maxTouchPoints } = window.navigator;
    const isMacSafari =
      /Version\/18.+Safari/.test(userAgent) &&
      platform !== "iPad" &&
      platform === "MacIntel" &&
      maxTouchPoints === 0;
    if (isMacSafari && GPU.gpu === "apple gpu (Apple GPU)" && !GPU.isMobile) {
      quality = "medium";
    }
  }

  const changeHologram = useCallback((hologram: number) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setCurrentHologram(hologram);
    }, 350);
  }, []);

  const revertHologram = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setCurrentHologram(routeHologram.current);
    }, 1000);
  }, []);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const sceneContextValues = {
    quality,
    is404,
    setIs404,
    routeHologram,
    currentHologram,
    setCurrentHologram,
    changeHologram,
    revertHologram,
    clientBannerScene: {
      sunPosition: undefined,
      sunAngle: 0,
    },
    isFooterContactHovered,
  };

  return (
    <context.Provider value={sceneContextValues}>
      <Suspense>{children}</Suspense>
    </context.Provider>
  );
};

export const useSceneContext = () => {
  const sceneContext = useContext(context);
  if (!context) {
    throw new Error(
      "useSceneContext must be used within a SceneContext.Provider"
    );
  }
  return sceneContext;
};
