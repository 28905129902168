"use client";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import useFramerMotion, { LazyMotion, m } from "@/hooks/useFramerMotion";
import { cn } from "@/utils";
import { useTranslations } from "next-intl";
import ChangeHologram from "../ui/ChangeHologram";

const ContactInfo = ({
  email = true,
  phone = true,
  address = false,
}: {
  email?: boolean;
  phone?: boolean;
  address?: boolean;
}) => {
  const framerMotionFeatures = useFramerMotion();
  const t = useTranslations("common");
  return (
    <LazyMotion features={framerMotionFeatures}>
      <div
        className={cn(
          "font-secondary leading-loose",
          "[&_a]:flex [&_a]:items-center [&_a]:gap-2",
          "[&_svg]:text-[22px]"
        )}>
        <div
          className={cn(
            "flex flex-wrap gap-x-6 max-lg:justify-center max-lg:text-center",
            "group"
          )}>
          {phone && (
            <div className={cn("text-center", "group")}>
              <ChangeHologram hologram={4}>
                <m.a
                  className="underline-link-span underline-link-inverted flex hover:text-accent"
                  whileTap={{ scale: 0.95 }}
                  href="tel:+41565000556">
                  <FiPhone />
                  <span>+41 56 500 05 56</span>
                </m.a>
              </ChangeHologram>
            </div>
          )}
          {email && (
            <div className={cn("text-center", "group")}>
              <ChangeHologram hologram={9}>
                <m.a
                  className="underline-link-span underline-link-inverted flex hover:text-accent"
                  whileTap={{ scale: 0.95 }}
                  href="mailto:studio@massif.ch">
                  <FiMail />
                  <span>studio@massif.ch</span>
                </m.a>
              </ChangeHologram>
            </div>
          )}

          {address && (
            <div className={cn("text-center", "group")}>
              <ChangeHologram hologram={7}>
                <m.a
                  className="underline-link-span underline-link-inverted flex hover:text-accent"
                  whileTap={{ scale: 0.95 }}
                  target="_blank"
                  href="https://maps.app.goo.gl/NYjwaunHY9sj6Keh8">
                  <FiMapPin />
                  <span>
                    <span>5400 Baden, {t("switzerland")}</span>
                  </span>
                </m.a>
              </ChangeHologram>
            </div>
          )}
        </div>
      </div>
    </LazyMotion>
  );
};

export default ContactInfo;
